import React, { useState } from 'react';
import logo from '../assests/logo.png';
import Products from '../components/Products';
import { Link } from 'react-scroll';
import Dummy from './dummy';
import Lottie from 'lottie-react';
import animationData from '../assests/Animation - 1710411006092.json';
import animationData2 from '../assests/Animation - 1710411423726.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faLinkedin, faYoutube, faTwitter, faFacebook } from '@fortawesome/free-brands-svg-icons';


const Main = () => {
  const [showContent, setShowContent] = useState(false);
  return (
    <>
      <style>
        {`
 @keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
`}
      </style>

      <div className="bg-white"  style={{ backgroundImage: 'linear-gradient(90deg, rgb(68, 144, 190),rgb(251, 254, 241))' }}>
        <header className="navbar flex justify-between items-center text-black py-4 px-6 sticky top-0 z-10">
          <div className="flex items-center">
            <img src={logo} alt="Logo" className="logo h-12" />
          </div>
          <nav className="flex items-center font-bold text-2xl text-blue-900">
            <Link to="home" spy={true} smooth={true} offset={-70} duration={500} className="px-4 hover:text-black cursor-pointer">Home</Link>

            <Link to="tab3" spy={true} smooth={true} offset={-70} duration={500} className="px-4 hover:text-black-300 cursor-pointer">Products</Link>

            <Link to="service" spy={true} smooth={true} offset={-70} duration={500} className="px-4 hover:text-black-300 cursor-pointer">Service</Link>
           
            <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} className="px-4 hover:text-black-300 cursor-pointer">Contact</Link>
          </nav>
        </header>

        <main>


          <section
            id="home"
            className="min-h-screen flex flex-col md:flex-row items-center justify-center mt-[-100px] relative"
            style={{ backgroundImage: 'linear-gradient(90deg, rgb(68, 144, 190),rgb(251, 254, 241))' }}
          >
            <div className="w-full md:w-1/2 flex flex-col items-center">
              <div style={{ position: 'relative', display: 'inline-block', cursor: 'pointer' }}>
                <Lottie
                  animationData={animationData2}
                  autoplay
                  loop
                  style={{ width: 200, height: 200, transition: 'transform 0.3s ease-in-out' }}
                  onMouseOver={() => setShowContent(true)}
                  onMouseOut={() => setShowContent(false)}
                  className={showContent ? 'animation-hovered' : ''}
                />
                {showContent && (
                  <div
                    style={{

                      position: 'absolute',
                      top: '50%',
                      right: '-250px', 
                      transform: 'translateY(-50%)',
                      width: '220px',
                      padding: '10px',
                      background: '#000022',
                      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
                      borderRadius: '10px',
                      zIndex: 9999, 
                      transition: 'right 0.3s ease-in-out',
                    }}
                    className={showContent ? 'content-visible' : ''}
                  >
                    <p style={{ margin: 0, color: 'white', fontFamily: 'Arial, sans-serif' }}>This system is designed to streamline various processes within a hospital to ensure efficient management of patients, staff, and resources.</p>
                  </div>
                )}
              </div>
              <h1 className="text-2xl md:text-2xl font-bold mb-4 text-blue-900" style={{ animation: 'slideInLeft 1.5s ease-in-out forwards' }}>
                Welcome to our Hospital Management System
              </h1>
              <h5 className=" mt-5 ml-7 text-center lg:text-left font-serif" style={{ animation: 'slideInRight 1.5s ease-in-out forwards' }}>
                Our Hospital Management System is meticulously designed to revolutionize hospital operations. It seamlessly integrates various departments, ensuring streamlined workflows and enhanced patient care. From patient management to staff coordination and resource allocation, our system optimizes efficiency to meet the evolving needs of modern healthcare.
              </h5>
            </div>
            <div className="w-full md:w-1/2 flex justify-center items-center mb-4">
              <Lottie animationData={animationData} loop autoplay style={{ width: 400, height: 400 }} />
            </div>
          </section>

          <section id="tab3">
            <Products />
          </section>

          <section id="service"
            className="py-2" style={{ minHeight: '600px' }}>
            <Dummy />
          </section>

          <section id="contact" className="py-8">

            <div className="container mx-auto flex flex-wrap justify-center">
              <div className="w-full md:w-1/3 mt-20 px-4 mb-10 md:mb-0 order-1 md:order-0 text-center md:text-left">
                <p className="text-xl font-semibold mb-4 text-white">Your digital journey begins here, where pixels meet purpose and code turns dreams into reality</p>
              </div>
              <div className="w-full md:w-1/3 px-4 mb-8 md:mb-0 mt-5 order-1 md:order-0 text-center">
                <h3 className="text-2xl font-bold mb-8 text-blue-900">CONTACT US</h3>
                <div className="mb-6">
                  <div className="flex items-center ">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white mr-2 mb-5" />
                    <p className="mb-0 text-white">No-11,1st Floor,3rd Street, K.K.Nagar, Madurai,Tamil Nadu,India</p>
                  </div>
                  <div className="flex items-center mb-2">
                    <FontAwesomeIcon icon={faPhone} className="text-white mr-2" />
                    <p className="mb-0 text-white">+91 6369070815</p>
                  </div>
                  <div className="flex items-center">
                    <FontAwesomeIcon icon={faEnvelope} className="text-white mr-2" />
                    <p className="mb-0"><a href="mailto:info@example.com" className="text-white hover:text-blue-300">info@fiveytech.com</a></p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/3 py-4 order-0 md:order-1 md:ml-auto mt-12">
                <h3 className="text-xl font-semibold mb-2 text-white text-center mr-14">Follow Us</h3>
                <div className="flex flex-col md:flex-col justify-center md:justify-end mt-4 px-40">
                  <a href="https://www.instagram.com/fiveytechnologies/?igshid=ODA1NTc5OTg5Nw%3D%3D" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} className="text-2xl text-gray-400 hover:text-blue-500" />
                  </a>
                  <a href="https://www.linkedin.com/company/fiveytech/" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faLinkedin} className="text-2xl text-gray-400 hover:text-blue-500" />
                  </a>
                  <a href="https://www.youtube.com/@FiveYTechnologies" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faYoutube} className="text-2xl text-gray-400 hover:text-red-500" />
                  </a>
                  <a href="https://twitter.com/fiveytech?t=dps8Wn6cdWZw2HIM5PNckg&s=08" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faTwitter} className="text-2xl text-gray-400 hover:text-blue-400" />
                  </a>
                  <a href="https://www.facebook.com/people/Five-Y-Technologies-Decoders/61554694599414/?mibextid=ZbWKwL" className="mr-4" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} className="text-2xl text-gray-400 hover:text-blue-700" />
                  </a>
                </div>
              </div>
            </div>

            
            <hr className="my-2 border-gray-400"></hr>
            <footer className="text-center text-gray-800 text-sm py-4">
              Copyright © 2023 Five Y Technologies All Rights Reserved
            </footer>
          </section>
        </main>
      </div>
    </>
  );
};



export default Main;
