import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './components/Main';

const App = () => {
  return (
    <Router>
      <Routes> 
        <Route path="/" element={<Main />} /> 
        <Route path="/home" element={<Main />} />
        <Route path="/service" element={<Main />} />
        <Route path="/tab3" element={<Main />} />
        <Route path="/contact" element={<Main />} />
      </Routes>
    </Router>
  );
};

export default App;
