import React, { useRef, useEffect, useState } from 'react';
import animeRegistration from '../assests/registration.json';
import animeAppointment from '../assests/appointment.json';
import animeBilling from '../assests/billing.json';
import animeConsultation from '../assests/consultation.json';
import animePharmacy from '../assests/pharmacy.json';
import Lottie from 'react-lottie';

function Products() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [animationsLoaded, setAnimationsLoaded] = useState(false);
    const refs = {
        registrationRef: useRef(null),
        appointmentRef: useRef(null),
        billingRef: useRef(null),
        docConsultationRef: useRef(null),
        pharmacyRef: useRef(null)
    };

    const animations = [
        { registration: refs.registrationRef, animation: animeRegistration },
        { appointment: refs.appointmentRef, animation: animeAppointment },
        { billing: refs.billingRef, animation: animeBilling },
        { consultation: refs.docConsultationRef, animation: animeConsultation },
        { pharmacy: refs.pharmacyRef, animation: animePharmacy }
    ];

    const handleSlideChange = (index) => {
        setCurrentIndex(index);
        const radio = document.getElementById(`s${index + 1}`);
        if (radio) {
            radio.checked = true;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            const newIndex = (currentIndex + 1) % animations.length;
            setCurrentIndex(newIndex);
        }, 5000); 
        handleSlideChange(currentIndex)
        return () => clearInterval(interval);
    }, [currentIndex]);

    useEffect(() => {
        const contentSlides = document.querySelectorAll('.content-slide');
        contentSlides.forEach((slide) => {
            slide.classList.remove('pop-up-active'); // Remove the class first
            setTimeout(() => {
                slide.classList.add('pop-up-active'); // Add the class after a short delay
            }, 100); // Adjust the delay as needed
        });
    }, [currentIndex]);
    

    const renderContent = () => {
        switch (currentIndex) {
            case 0:
                return <div className={`text-center content-slide text-blue-900 mb-20`}>New patients must register by providing basic details and payment. They receive a UHID and an ID card. If details change, they can update them, and a new ID card is issued with the same UHID. Registration is one-time; subsequent visits only require consultation.</div>;
            case 1:
                return <div className={`text-center content-slide text-blue-900 mb-20`}>After registration, patients proceed to consultation where they provide their UHID and specify the doctor and department they wish to consult with. They are assigned a consultation time and required to pay the consultation fees at the billing counter before proceeding to the OP session.</div>;
            case 2:
                return <div className={`text-center content-slide text-blue-900 mb-20`}>After registration and consultation, patient details are displayed in the billing counter queue. At the counter, patients provide their name or mobile number to locate their information. They choose between cash or online payment methods. For cash, a unique transaction number is generated; for online, options like GPay or PhonePe are available. Once paid, a printed bill is provided or sent via WhatsApp if requested.</div>;
            case 3:
                return <div className={`text-center content-slide text-blue-900 mb-20`}>
                    After collecting basic info, patients proceed to the doctor's queue. They're called in order and consulted by the doctor. The doctor assesses their health issue, performs checks, and prescribes medication if needed. The doctor fills out a prescription form with the required medication from the pharmacy stock. Once the consultation ends, patients move to the pharmacy billing section.
                </div>;
            case 4:
                return <div className={`text-center content-slide text-blue-900`}>1. **Purchase**: Medicines are bought in bulk from various brand owners.<br />
            
            
                2. **Stock**: The medicines purchased are automatically updated in the stock. When a medicine is purchased, its quantity is reduced from the stock automatically.<br/>
                
                
                3. **Billing**: Patients who have completed their consultation and received a prescription are queued up for billing. When a patient's details are viewed, the prescribed medicines, along with their MRP, are displayed. If a particular medicine is not available in stock or is expired, an alert is shown during billing. Adjustments must be made accordingly before processing the billing.</div>;
            default:
                return null;
        }
    };
    
    
    
    return (
        <div className='main'>
            <style>
                {`
                .product {
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    height: 100vh;
                    font-family: 'Urbanist', sans-serif;
                }
                
                input[type="radio"] {
                    display: none;
                }
                
                .contain {
                    width:60vw;
                    height: 100%;
                    /* Adjust width as needed */
                    display: flex;
                    max-height: 600px;
                    justify-content: center;
                    align-items: center;
                    transform-style: preserve-3d;
                    flex-direction: column;
                    margin-bottom: 100px;
                }
                              
                
                .cards {
                    position: relative;
                    height: 500px;
                    width: 430px;
                    margin-bottom: 20px;
                    perspective: 1000px;
                    transform-style: preserve-3d;
                }
                
                .cards label {
                    position: absolute;
                    width: 300px;
                    height: 350px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    cursor: pointer;
                    transition: transform 0.55s ease;
                }
                
                .cards .card {
                    position: relative;
                    height: 100%;
                    background-color: #323444;
                    border-radius: 10px;
                    padding: 30px 35px;
                }
                
                .cards .image {
                    display: flex;
                    justify-content: space-between;
                }
                
                .cards .image .img {
                    border-radius: 5px;
                    box-shadow: 15px 15px 40px rgba(0, 0, 0, 50%);
                    margin-top: 23px;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    transition: all .7s;
                }
                
                .cards .image .img:hover {
                    transform: scale(1.05);
                }
                
                .cards .image .dots {
                    text-align: center;
                }
                
                .dots {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-bottom: 10px;
                }
                
                .dots div:nth-child(1) {
                    background-color: var(--current-color1);
                }
                
                .dots div:nth-child(2) {
                    background-color: #5b85f9;
                }
                
                .dots div:nth-child(3) {
                    background-color: #000;
                }
                
                .card .infos {
                    display: block;
                    text-align: center;
                    padding-top: 60px;

                }
                
                .card .infos span {
                    display: block;
                }
                
                .infos .name {
                    font-size: 20px;
                    color: var(--current-color1);
                    margin-bottom: 20px;
                    letter-spacing: 1px;
                    transition: all .6s ease;
                }
                
                #s1:checked~.cards #slide4,
                #s2:checked~.cards #slide5,
                #s3:checked~.cards #slide1,
                #s4:checked~.cards #slide2,
                #s5:checked~.cards #slide3 {
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 45%);
                    transform: translate3d(-70%, 0, -220px);
                    --current-color1: #eceaed;
                    --currente-color2: #404457;
                }
                
                #s1:checked~.cards #slide5,
                #s2:checked~.cards #slide1,
                #s3:checked~.cards #slide2,
                #s4:checked~.cards #slide3,
                #s5:checked~.cards #slide4 {
                    box-shadow: 0 20px 40px rgba(0, 0, 0, 45%);
                    transform: translate3d(-35%, 0, -120px);
                    --current-color1: #eceaed;
                    --currente-color2: #404457;
                }
                
                #s1:checked~.cards #slide1,
                #s2:checked~.cards #slide2,
                #s3:checked~.cards #slide3,
                #s4:checked~.cards #slide4,
                #s5:checked~.cards #slide5 {
                    box-shadow: 0 25px 50px rgba(0, 0, 0, 50%);
                    transform: translate3d(0, 0, 0);
                    --current-color1: #fad00c;
                    --currente-color2: #eceaed;
                }
                
                #s1:checked~.cards #slide2,
                #s2:checked~.cards #slide3,
                #s3:checked~.cards #slide4,
                #s4:checked~.cards #slide5,
                #s5:checked~.cards #slide1 {
                    box-shadow: 0 20px 40px rgba(0, 0, 0, 45%);
                    transform: translate3d(35%, 0, -120px);
                    --current-color1: #eceaed;
                    --currente-color2: #404457;
                }
                
                #s1:checked~.cards #slide3,
                #s2:checked~.cards #slide4,
                #s3:checked~.cards #slide5,
                #s4:checked~.cards #slide1,
                #s5:checked~.cards #slide2 {
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 45%);
                    transform: translate3d(70%, 0, -220px);
                    --current-color1: #eceaed;
                    --currente-color2: #404457;
                }
                
                .heading {
                    margin-bottom: 20px;
                    color: white;
                    backgroundImage: linear-gradient(90deg, rgb(68, 144, 190),rgb(251, 254, 241))                
                
                .main {
                    backgroundImage: linear-gradient(90deg, rgb(68, 144, 190),rgb(251, 254, 241))                
                }
                
               
                @keyframes popUpAnimation {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Apply the animation to the content-slide class */
.content-slide {
    opacity: 0; /* Initially hide the content */
}

/* Add the animation trigger class */
.pop-up-active {
    animation: popUpAnimation 1s forwards ease-in-out;
}

            
                
               
                
                `}
            </style>

            <div className="heading mt-0">
            <h2 className="text-3xl font-bold text-center text-blue-900 mb-8 ">OUR PRODUCTS</h2>
            </div>

            <div className='product'>
                <div className='contain mt-40'>
                    <input type="radio" name="slider" id="s1" checked />
                    <input type="radio" name="slider" id="s2" />
                    <input type="radio" name="slider" id="s3" />
                    <input type="radio" name="slider" id="s4" />
                    <input type="radio" name="slider" id="s5" />

                    <div className='cards'>
                        {animations.map((animation, index) => (
                            <label key={index} htmlFor={`s${index + 1}`} id={`slide${index + 1}`}>
                                <div className='card' onClick={() => handleSlideChange(index)}>
                                    <div className='image'>
                                        <Lottie options={{ animationData: animation.animation, loop: true, autoplay: false }} height="100%" width="100%" />
                                        <div className='dots'>
                                            <div> </div>
                                            <div> </div>
                                            <div> </div>
                                        </div>
                                    </div>
                                    <div className='infos '>
                                        <span className='name'>{Object.keys(animation)[0].charAt(0).toUpperCase() + Object.keys(animation)[0].slice(1)}</span>
                                    </div>
                                </div>
                            </label>
                        ))}
                    </div>
                </div>
                <div className={`content text-white w-[35vw] ml-[2vw] h-[400px] rounded-[24px] flex items-center justify-center`}>
    <div >
        {renderContent()}
    </div>
</div>

 </div>
        </div>
    );
}

export default Products;
