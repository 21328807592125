import React from 'react';
import img1 from '../assests/img1.gif';
import img2 from '../assests/img22.gif';
import img3 from '../assests/img3.gif';
import img4 from '../assests/img4.gif';
import img5 from '../assests/img55.gif';
import img6 from '../assests/img66.gif';
import '../styles/services.css';
import nurse from '../assests/nurse.png';

const Dummy = () => {
    return (

        <div className='main'>

            <style>
                {
                    `
            .heading {
                margin-bottom: 20px;
                color: white;
                backgroundImage: linear-gradient(90deg, rgb(68, 144, 190),rgb(251, 254, 241))   

                .main {
                    backgroundImage: linear-gradient(90deg, rgb(68, 144, 190),rgb(251, 254, 241))           
                }

                .service-image {
                    width : 100px;
                    background-color : transparent;
                }

            `
                }
            </style>

            <div className="heading">
                <h2 className="text-3xl font-bold text-center text-blue-900 mb-8 ">OUR SERVICES</h2>
            </div>

            <div className='container'>
                <div className='row'>
                    <div className='col-2'>
                        <img src={nurse} alt="Service Image" className="service-image" />

                    </div>

                        <div className='col-10 service'>
                            <div className='card'>
                                <div className='imgBx'>
                                    <img src={img1} alt="no capture" />
                                </div>
                                <div className='contentBx'>
                                    <div className='content'>
                                        <h3>patient registration</h3>
                                        <p>Our system streamlines patient registration, simplifying data entry and record-keeping.
                                            Features include demographic data collection, efficient medical history storage,
                                            and accurate insurance information management, optimizing care delivery and decision-making.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='imgBx'>
                                    <img src={img2} alt="no capture" />
                                </div>
                                <div className='contentBx'>
                                    <div className='content'>
                                        <h3>billing</h3>
                                        <p>Our system automates billing, simplifying invoicing,
                                            payments, and insurance claims for hospitals, enhancing financial efficiency.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='imgBx'>
                                    <img src={img3} alt="no capture" />
                                </div>
                                <div className='contentBx'>
                                    <div className='content'>
                                        <h3>outpatient</h3>
                                        <p>Our system simplifies The nurse station manages patient billing and queues patient lists,
                                            where nurses review fundamental reports and formal tests and OPstatistics</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='imgBx'>
                                    <img src={img4} alt="no capture" />
                                </div>
                                <div className='contentBx'>
                                    <div className='content'>
                                        <h3>doctor</h3>
                                        <p>In the doctor module, completed formal tests are reviewed, followed by patient examination, treatment, and prescription writing.
                                            Prescriptions are then directly sent to the pharmacy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='imgBx'>
                                    <img src={img5} alt="no capture" />
                                </div>
                                <div className='contentBx'>
                                    <div className='content'>
                                        <h3>pharmacy</h3>
                                        <p>Our system simplifies the pharmacy module, doctor prescriptions are received, checked for stock availability, and dispensed to the patient.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card'>
                                <div className='imgBx'>
                                    <img src={img6} alt="no capture" />
                                </div>
                                <div className='contentBx'>
                                    <div className='content'>
                                        <h3>human resource</h3>
                                        <p>Our system optimizes staff scheduling, ensuring adequate coverage, minimizing costs,
                                            and enhancing patient care quality through intuitive tools for shift planning and workload distribution.</p>
                                    </div>
                                </div>
                            </div>


                        </div>
                </div>


            </div>
        </div>
    );
}




export default Dummy;
